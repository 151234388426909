import React, { Component } from 'react';
import SearchWidgetState from './SearchWidgetState';
import './ArrayHelpers.js';
import Config from './Config';
import DataService from './DataService';
import SearchFilters from './SearchFilters';
import SelectBox from './SelectBox';
import { isMobileViewport, satelliteTrack } from './Utils';
import cookies from 'js-cookie';
import './App.css';

class SearchWidget extends Component {
	constructor(props) {
		super(props);
		const config = new Config();
		const store = {};
		this.config = config;
		this.store = store;
		this.dataService = new DataService(config, store);
		this.searchFilters = new SearchFilters(config, store);
		this.state = new SearchWidgetState();
	}

	async componentDidMount() {
		await this.fetchSettings();
		// dtm tracking
		satelliteTrack();
		this.setRedirectionURL();
	}

	async fetchSettings() {
		let { state, store, dataService } = this;
		const isMobile = isMobileViewport();
		try {
			await dataService.fetchToken();
			await dataService.fetchAdmin();
			if (!this.config.allowShopping) {
				this.setState({...state, allowShopping: false});
				return;
			}
			if (isMobile) {
				this.setStateForNoData();
				return;
			}
			await Promise.all([dataService.fetchTrades(), dataService.fetchPorts(), dataService.fetchProducts()]);
			// if we are not using cached products, serialize the raw response
			if (!store.products) {
				dataService.serializeProducts();
			}
			let newState = Object.assign({}, state);
			newState.didFetchData = true;
			this.setState(newState);
		} catch (e) {
			this.setStateForNoData();
			// eslint-disable-next-line no-undef
			typeof _satellite === 'object' && _satellite.track && _satellite.track('errorTracking');
		}
	}

	setStateForNoData() {
		let newState = {...this.state};
		newState.willFetchData = false;
		this.setState(newState);
	}

	updateState(updatedPropertyName, updatedValue) {
		let state = {
			...this.state
		};
		state[updatedPropertyName] = updatedValue;
		this.setState(state);
	}

	setRedirectionURL() {
		const {country, ube} = this.config;
		const isRedirectionFromReact = this.dataService.localStore.storage[`pcl-ube-search-${country}-rvc-${ube}`];
		let newState = {...this.state};
		newState.redirectionUrl = '/cruise-search/recently-viewed/?recentlyViewed=true';
        let cookie = cookies.get('rvc');
		newState.hasRecentlyViewed = !!cookie || isRedirectionFromReact;
		this.setState(newState);
	}

	hasRecentlyViewed(isRedirectionFromReact) {
		let cookie = cookies.get('rvc');
		return !!cookie || isRedirectionFromReact;
	}

	handleChange(event, propertyName) {
		const { value, options } = event.target;
		this.updateState(propertyName, value);
		/* eslint-disable */
		if (window.digitalData && window.digitalData.search) {
			switch (propertyName) {
				case 'selectedTrade':
					digitalData.search.destination = options[options.selectedIndex].label;
					digitalData.search.destinationCode = value;
					break;
				case 'selectedDate':
					digitalData.search.date = value;
					break;
				case 'selectedPort':
					digitalData.search.port = options[options.selectedIndex].label;
					digitalData.search.portCode = value;
					break;
				case 'selectedDuration':
					digitalData.search.duration = value;
					break;
			}
		}
		/* eslint-enable */
	}

	handlePastGuestChange() {
		this.setState({
			...this.state,
			isPastGuest: !this.state.isPastGuest
		});
	}

	renderSelectBoxes(fields) {
		const label = {
			selectedTrade: 'DESTINATION',
			selectedDate: 'MONTH/YEAR',
			selectedPort: 'START/END PORT',
			selectedDuration: 'CRUISE LENGTH'
		}
		return fields.map((field) => {
			const selectedOption = field.options.find((option) => {
				return option.isSelected;
			});
			return (
				<SelectBox
					key={field.name}
					fieldName={field.name}
					label={label[field.selectedPropertyKey]}
					selectedPropertyKey={field.selectedPropertyKey}
					options={field.options}
					handleChange={(event, selectedPropertyKey) => { this.handleChange(event, selectedPropertyKey); }}
					value={selectedOption.value} />
			);
		});
	}

	renderBigButtonHTML(isHiddenForDesktop, hasRecentlyViewed, redirectionUrl) {
		return (
			<div className={`${ isHiddenForDesktop ? 'hidden-md-up' : ''} align-center`}>
				<div className='bigButton'>
					<a href={`${this.config.wwwHost}/cruise-search/cruises`} className="button green-btn font-size-p5" data-test-id="view-results-mobile">Find a Cruise Vacation</a>
				</div>
				{this.renderMobileRecentlyViewed(hasRecentlyViewed, redirectionUrl)}
			</div>
		);
	}

	renderMobileRecentlyViewed(hasRecentlyViewed, redirectionUrl) {
		if (hasRecentlyViewed) {
			return (
				<div className="col-xs-pad-15-top">
					<a href={redirectionUrl}>See Recently Viewed</a>
				</div>
			);
		}
	}

	renderRecentlyViewed(hasRecentlyViewed, redirectionUrl) {
		if (hasRecentlyViewed) {
			return (
				<div className="recently-viewed-wrapper">
					<a className="recently-viewed-anchor red-hat-text-medium" href={redirectionUrl}>Recently Viewed</a>
				</div>
			);
		}
	}

	render() {
		const { state, searchFilters } = this;
		const { allowShopping, willFetchData, didFetchData, hasRecentlyViewed, redirectionUrl } = state;
		let hideBigButtonOnDesktop = true;
		if (allowShopping) {
			if (willFetchData) {
				if (didFetchData) {
					searchFilters.filter(state);
					const fields = searchFilters.buildFields(state);
					const url = searchFilters.buildUrl(state);
					return (
						<div className='search-container light-bg rounded-corners-light'>
							<div className="ube-search-widget col-xs-12 col-xs-pad-20-top col-xs-pad-20-bottom col-md-pad-0-top col-md-pad-0-bottom col-xs-pad-20 col-md-pad-40" aria-labelledby="widget-header" role="group">
								<div className="widget-fields-wrapper hidden-sm-down">
									<h2 id="widget-header" className="visuallyhidden">Find a cruise</h2>
									<div className="widget-fields row">
										{ this.renderSelectBoxes(fields) }
										<div id="search-widget-submit" className="white">
											<a href={url} className="search-widget-submit-btn green-btn button white font-size-p2 red-hat-text-medium" data-track="search-source" data-track-id="cruise-widget" data-test-id="view-results">VIEW CRUISES</a>
										</div>
									</div>
									{ this.renderRecentlyViewed(hasRecentlyViewed, redirectionUrl) }
								</div>
								{ this.renderBigButtonHTML(hideBigButtonOnDesktop, hasRecentlyViewed, redirectionUrl) }
							</div>
						</div>
					);
				}
			} else {
				hideBigButtonOnDesktop = false;
				return (
					<div className='search-container light-bg rounded-corners-light'>
						<div className="ube-search-widget col-xs-12 col-xs-pad-20-top col-xs-pad-20-bottom col-md-pad-0-top col-md-pad-0-bottom col-xs-pad-20 col-md-pad-40">
							{this.renderBigButtonHTML(hideBigButtonOnDesktop, hasRecentlyViewed, redirectionUrl)}
						</div>
					</div>
				);
			}
		} else {
			return (
				<div className='search-container light-bg rounded-corners-light'>
					<div className="ube-search-widget no-shopping hidden"></div>
				</div>
			);
		}
	}
}

export default SearchWidget;
